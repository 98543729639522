import styled from "styled-components";
import { Offcanvas, CloseButton, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";

import footerBackground from "../../assets/loginBackground.png";

export const StyledUnauthorizedLayout = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  minWidth: "100vw",
  height: "100vh",
  padding: "25px 0px 50px",
  overflow: "hidden",
});

export const StyledAuthorizedLayout = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  minWidth: "100vw",
  backgroundAttachment: "fixed",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  position: "relative",
  boxSizing: "border-box",
});

export const StyledLayout = styled.div({
  display: "flex",
  alignItems: "stretch",
  zIndex: 1,
});

export const LogoTitle = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "28px",
  color: "#FFFFFF",
  gap: "20px",
  fontFamily: "Stolzl",
  zIndex: 1,
});

export const LogoTitleAuth = styled(LogoTitle)({
  fontFamily: "BENZIN",
  fontWeight: 900,
  marginBottom: "0px",
  width: "105px",
  textTransform: "uppercase",
  lineHeight: "18px",
  fontSize: "18px",

  "@media screen and (max-width: 1000px)": {
    display: "none",
  },
});

export const MainContent = styled.div({
  width: "100%",
  minHeight: "100vh",
  padding: "10px 40px 0px 127px",

  "@media screen and (max-width: 1200px)": {
    padding: "10px 10px 0px",
  },
});

export const MainContentHeader = styled.div({
  display: "flex",
  // 15.6
  marginBottom: "20px",
  alignItems: "center",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "28px",
  color: "#FFFFFF",
  gap: "20px",
  justifyContent: "space-between",
  maxWidth: "1100px",

  "@media screen and (max-width: 1200px)": {
    margin: "0px 15px 20px",
    maxWidth: "1200px",
  },

  "@media screen and (max-width: 1000px)": {
    margin: "0px 15px 20px",
    maxWidth: "1000px",
  },
});

export const MainContentBody = styled.div({
  minWidth: "100%",
  height: "calc(100% - 135px)",
});

export const Footer = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-end",
  minHeight: "64px",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "24px",
  color: "#565B7B",
  boxSizing: "border-box",
  fontFamily: "Stolzl",
  background: `url(${footerBackground})`,
  position: "absolute",
  bottom: "0px",
  width: "100%",
  height: "128px",
  backgroundSize: "cover",
});

export const StyledAdminLayout = styled.div({
  minWidth: "100vw",
  minHeight: "100vh",
  background: "white",
});

export const StyledAdminContentContainer = styled.div({
  overflowY: "auto",
  width: "100%",
  height: "100%",
  padding: "15px",
});

export const AdminHeader = styled.header({
  paddingLeft: "15px",
  display: "flex",
  alignItems: "center",
  height: "50px",
  backgroundColor: "#343a40",
  cursor: "pointer",
  position: "sticky",
  top: "0px",
  zIndex: 5,
});

export const AdminSidebarHeader = styled(Offcanvas.Header)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  borderBottom: "1px solid black",
  padding: "10px",
});

export const AdminSidebarTitle = styled(Offcanvas.Title)({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

export const AdminSidebarCloseBtn = styled(CloseButton)`
  width: 25px !important;
  height: 25px !important;
  transition: transform 0.5s;
  box-sizing: border-box !important;
  padding: 0 !important;
  border-radius: 50% !important;
  opacity: 1 !important;
  margin-top: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 0 !important;
  &:hover {
    transform: rotate(90deg);
  }
`;

export const AdminSidebarLink = styled(Link)({
  textDecoration: "none",
  cursor: "pointer",
});

export const AdminSidebarListItem = styled(ListGroup.Item)`
  cursor: pointer;
  font-weight: 600;
  transition: border-left 0.2s;
  &:hover {
    border-left: 7px solid #0d6efd;
  }
`;

export const StoneFooter = styled.div({
  position: "absolute",
  height: "165px",
  width: "100%",
  background: `url(${footerBackground})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  bottom: "0px",

  "@media screen and (max-width: 1000px)": {
    display: "none",
  },
});

export const FlexBlock = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "10px",
});

export const StyledVideo = styled.video({
  position: "fixed",
  minWidth: "100%",
  objectFit: "fill",
  top: 0,
  left: 0,
});

export const MainContentTitle = styled.div({
  width: "100%",
  display: "flex",
  fontSize: "31px",
  lineHeight: "40px",
  textTransform: "uppercase",
  fontWeight: 800,
  fontFamily: "Benzin",
  marginBottom: "15px",
  color: "#FFF",
  textDecoration: "none",

  "@media screen and (max-width: 1200px)": {
    width: "100%",
    justifyContent: "center",
  },

  "@media screen and (max-width: 1000px)": {
    width: "100%",
    justifyContent: "center",
    textAlign: "center",
  },
});
