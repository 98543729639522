import { Icon } from "components/index";
import { useEffect, useMemo } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  StyledUnauthorizedLayout,
  LogoTitle,
  StoneFooter,
  StyledVideo,
} from "./styles";
import video from "assets/BG.mp4";
import {
  ImageWrapper,
  ImageWrapperBlock,
  LoginFormWrapper,
  LoginPage,
  LoginPageContent,
} from "modules/Login/styles";
// images
import Astronaut5 from "assets/Astronaut5.png";
import Character1 from "assets/character/Character1.gif";
import Character2 from "assets/character/Character2.gif";
import Character3 from "assets/character/Character3.gif";
import Character4 from "assets/character/Character4.gif";

const UnauthorizedLayout: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = window.localStorage.getItem("token");

    if (token) {
      navigate("/profile");
    }
  }, [navigate]);

  const formImage = useMemo(() => {
    switch (location.pathname) {
      case "/reset-password":
        return <ImageWrapper style={{ height: "600px" }} src={Character2} />;
      case "/code-verification":
        return <ImageWrapper style={{ height: "650px" }} src={Character3} />;
      case "/new-password":
        return <ImageWrapper style={{ height: "700px" }} src={Character4} />;
      case "/registration":
        return <ImageWrapper style={{ height: "650px" }} src={Astronaut5} />;
      default:
        return <ImageWrapper style={{ height: "600px" }} src={Character1} />;
    }
  }, [location.pathname]);

  return (
    <StyledUnauthorizedLayout>
      <LogoTitle>
        <Icon name="logo" width={58} height={58} />

        <p>Space Game</p>
      </LogoTitle>

      <StyledVideo src={video} autoPlay muted loop />

      <LoginPage>
        <LoginPageContent>
          <LoginFormWrapper>
            <Outlet />
          </LoginFormWrapper>

          <ImageWrapperBlock>{formImage}</ImageWrapperBlock>
        </LoginPageContent>
      </LoginPage>

      <StoneFooter />
    </StyledUnauthorizedLayout>
  );
};

export default UnauthorizedLayout;
