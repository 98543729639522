import styled from "styled-components";
import background from "assets/radioBackground.png";
import activeBackground from "assets/activeRadioBackground.png";

export const StyledInputRadio = styled.div<{ withFlexWrap: boolean }>(
  ({ withFlexWrap }) => ({
    display: "flex",
    position: "relative",
    flexWrap: withFlexWrap ? "wrap" : "nowrap",
    gap: "5px",
  })
);

export const StyledInputWrapper = styled.div<{
  width: string;
  isSelected: boolean;
  height: string;
}>(({ width, isSelected, height }) => ({
  width,
  height,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  background: `url(${isSelected ? activeBackground : background})`,
  backgroundSize: "contain",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",

  "& input": {
    opacity: 0,
    width,
    height,
    position: "absolute",
    cursor: "pointer",
  },

  "@media screen and (max-width: 1200px)": {
    height: "70px",
  },

  "@media screen and (max-width: 1000px)": {
    height: "55px",
  },
}));
