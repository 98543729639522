import {
  BalanceAmount,
  BalanceTextWrapper,
  BalanceTitle,
  IconHolder,
  StyledBalanceCard,
} from "./styles";
import { ReactComponent as WalletIcon } from "assets/walletIcon.svg";
import { useTranslate } from "redux/hooks";

type BalanceCardProps = {
  balance: string;
};

const BalanceCard: React.FC<BalanceCardProps> = ({ balance }) => {
  const translate = useTranslate();

  return (
    <StyledBalanceCard>
      <IconHolder>
        <WalletIcon />
      </IconHolder>

      <BalanceTextWrapper>
        <BalanceTitle>{translate("YOUR_BALANCE")}</BalanceTitle>

        <BalanceAmount>{balance}</BalanceAmount>
      </BalanceTextWrapper>
    </StyledBalanceCard>
  );
};

export default BalanceCard;
