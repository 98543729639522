import { Outlet, useLocation, useNavigate } from "react-router-dom";
// components
import {
  Icon,
  SelectInput,
  SideBar,
  Notifications,
  MobileMenu,
  Verification,
  ModalContainer,
  Loader,
} from "components/index";
// styles
import {
  StyledAuthorizedLayout,
  MainContent,
  MainContentHeader,
  MainContentBody,
  StyledLayout,
  Footer,
  LogoTitleAuth,
  FlexBlock,
  StyledVideo,
  MainContentTitle,
  AdminSidebarLink,
} from "./styles";
import { useAppDispatch, useAppSelector, useTranslate } from "redux/hooks";
import { changeLanguage, setUserData } from "redux/stores/userSlice";
import video from "assets/BG.mp4";
import { useAuthMeQuery } from "redux/api/userApi";
import { useEffect } from "react";
import background from "assets/background.png";

const data = [
  { value: "RU", label: "RU" },
  { value: "EN", label: "EN" },
];

const titles = {
  "/profile": "PERSONAL_AREA",
  "/wallet": "WALLET",
  "/wallet/history": "WITHDRAW_HISTORY",
};

const AuthorizedLayout = () => {
  const { language, userData } = useAppSelector("user");
  const dispatch = useAppDispatch();
  const translate = useTranslate();
  const token = window.localStorage.token;
  const { data: response, isLoading, refetch } = useAuthMeQuery(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    refetch();
  }, [location]);

  useEffect(() => {
    if (!token) {
      window.localStorage.removeItem("token");
      dispatch(setUserData(null));
      navigate("/");
    }

    if (response) {
      if (
        response.status === "success" &&
        response.user.is_admin &&
        !location.pathname.includes("/Sl6ufptgfk")
      ) {
        navigate(`/Sl6ufptgfk/users`);
      }

      dispatch(setUserData(response.user));
    }

    if (response && response.status === "error") {
      window.localStorage.removeItem("token");
      dispatch(setUserData(null));
      navigate("/");
      return;
    }
  }, [navigate, dispatch, token, response]);

  return (
    <StyledAuthorizedLayout>
      <StyledVideo src={video} autoPlay muted loop poster={background} />

      <StyledLayout>
        <SideBar />

        <MainContent>
          <MainContentHeader>
            <AdminSidebarLink to="/profile">
              <FlexBlock>
                <Icon name="logo" width={55} height={55} />

                <LogoTitleAuth>Space Game</LogoTitleAuth>
              </FlexBlock>
            </AdminSidebarLink>

            <FlexBlock>
              <SelectInput
                transparent
                data={data}
                value={language}
                handleChange={(value) =>
                  dispatch(changeLanguage(value as Dictionary))
                }
              />

              {userData && <Verification />}

              <Notifications />

              <MobileMenu />
            </FlexBlock>
          </MainContentHeader>

          <MainContentBody>
            <MainContentTitle>
              {/* @ts-ignore */}
              {translate(titles[location.pathname])}
            </MainContentTitle>

            {isLoading ? <Loader /> : <Outlet />}
          </MainContentBody>
        </MainContent>
      </StyledLayout>

      <Footer>© 2023 All rights reserved</Footer>

      <ModalContainer />
    </StyledAuthorizedLayout>
  );
};

export default AuthorizedLayout;
