import { Loader } from "components/index";
import { ReactElement, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthMeQuery } from "redux/api/userApi";
import { useAppDispatch } from "redux/hooks";
import { setUserData } from "redux/stores/userSlice";

const ContentWithLoader: React.FC<{ children: ReactElement }> = ({
  children,
}) => {
  const dispatch = useAppDispatch();
  const { data: response, isLoading } = useAuthMeQuery(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (response) {
      if (
        response.status === "success" &&
        response.user.is_admin &&
        !location.pathname.includes("/Sl6ufptgfk")
      ) {
        navigate(`/Sl6ufptgfk/users`);
      }

      if (response.status === "error") {
        window.localStorage.removeItem("token");
        dispatch(setUserData(null));
        navigate("/");
        return;
      }

      dispatch(setUserData(response.user));
    }
  }, [navigate, dispatch, location, response]);

  return isLoading ? <Loader /> : children;
};

export default ContentWithLoader;
