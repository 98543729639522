import { lazy, Suspense } from "react";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { PaymentMethods } from "modules/Admin/PaymentMethods";
import { AuthorizedLayout, UnauthorizedLayout, AdminLayout } from "./Layouts";

// modules
import {
  Profile,
  // WalletDetails,
  Users,
  Bets,
  Reports,
  Documents,
  LoginForm,
} from "modules/index";
import ContentWithLoader from "./Layouts/ContentWithLoader";
import { AdminSettings } from "modules/Admin/Settings";

// LOGIN
const ResetPassword = lazy(() => import("modules/Login/ResetPassword"));
const CodeVerification = lazy(() => import("modules/Login/CodeVerification"));
const NewPassword = lazy(() => import("modules/Login/NewPassword"));
const Registration = lazy(() => import("modules/Login/Registration"));

// MAIN
const Wallet = lazy(() => import("modules/Wallet"));
const WalletHistory = lazy(() => import("modules/WalletHistory"));
const Game = lazy(() => import("components/Game"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <UnauthorizedLayout />,
    children: [
      {
        path: "/",
        element: <LoginForm />,
      },
      {
        path: "/reset-password",
        element: (
          <Suspense>
            <ResetPassword />
          </Suspense>
        ),
      },
      {
        path: "/code-verification",
        element: (
          <Suspense>
            <CodeVerification />
          </Suspense>
        ),
      },
      {
        path: "/new-password",
        element: (
          <Suspense>
            <NewPassword />
          </Suspense>
        ),
      },
      {
        path: "/registration",
        element: (
          <Suspense>
            <Registration />
          </Suspense>
        ),
      },
      {
        path: "*",
        element: <Navigate to="/" />,
      },
    ],
  },
  {
    element: <AuthorizedLayout />,
    children: [
      {
        path: "/profile",
        element: <Profile />,
      },
      {
        path: "/wallet",
        element: (
          <Suspense>
            <Wallet />
          </Suspense>
        ),
      },
      {
        path: "/game",
        element: (
          <Suspense>
            <Game />
          </Suspense>
        ),
      },
      // {
      //   path: "/wallet/details",
      //   element: <WalletDetails />,
      // },
      {
        path: "/wallet/history",
        element: (
          <Suspense>
            <WalletHistory />
          </Suspense>
        ),
      },
      {
        path: "*",
        element: <Navigate to="/profile" />,
      },
    ],
  },
  {
    path: `/Sl6ufptgfk`,
    element: (
      <ContentWithLoader>
        <AdminLayout />
      </ContentWithLoader>
    ),
    children: [
      { path: "users", element: <Users /> },
      // { path: "statistics", element: <Statistics /> },
      { path: "kyc", element: <Documents /> },
      { path: "bets", element: <Bets /> },
      { path: "reports/:tab?", element: <Reports /> },
      { path: "payment-methods", element: <PaymentMethods /> },
      { path: "settings", element: <AdminSettings /> },
      {
        path: "*",
        element: <Navigate to="/Sl6ufptgfk/users" />,
      },
    ],
  },
]);

const Router = () => <RouterProvider router={router} />;

export default Router;
