import { ICON } from "../constants";

const Icon: React.FC<IconProps> = ({
  name,
  width = 20,
  height = 20,
  onClick,
}) => {
  return (
    <img
      onClick={() => (onClick ? onClick() : undefined)}
      width={width}
      height={height}
      src={ICON[name]}
      alt={name}
    />
  );
};

export default Icon;
