import { useEffect, useRef } from "react";
import { useAppSelector } from "redux/hooks";
import { StyledGame } from "./styles";

const Game = () => {
  const token = window.localStorage.getItem("token");
  const { language, userData } = useAppSelector("user");
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  useEffect(() => {
    if (iframeRef.current) {
      const availableHeight = document.body.clientHeight - 150;

      iframeRef.current.height = `${
        availableHeight < 664 ? 664 : availableHeight
      }px`;
    }
  }, []);

  useEffect(() => {
    if (iframeRef.current) {
      iframeRef.current.contentWindow?.postMessage(
        { type: "language", value: language },
        "*"
      );
    }
  }, [language]);

  return (
    <StyledGame>
      <iframe
        referrerPolicy="strict-origin"
        ref={iframeRef}
        title="crypto-game"
        allowFullScreen
        width="100%"
        src={`${process.env.REACT_APP_GAME_BASE_URL}?token=${token}&userID=${userData?.id}&language=${language}`}
      />
    </StyledGame>
  );
};

export default Game;
