import { StyledLoaderWrapper } from "./styles";
import { ReactComponent as Cosmo } from "assets/cosmonaft.svg";

const Loader: React.FC = () => {
  return (
    <StyledLoaderWrapper>
      <div>
        <Cosmo width={200} height={120} />
      </div>
    </StyledLoaderWrapper>
  );
};

export default Loader;
