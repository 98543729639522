import React, { ReactElement } from "react";
import { StyledInputRadio, StyledInputWrapper } from "./styles";

type InputRadioProps = {
  name: string;
  values: { value: string; icon: ReactElement }[];
  selectedValue: string;
  onChange: (value: string) => void;
  withFlexWrap: boolean;
  widthItem: string;
  heightItem: string;
};

const InputRadio: React.FC<InputRadioProps> = ({
  values,
  selectedValue,
  name,
  onChange,
  withFlexWrap,
  widthItem = "49%",
  heightItem = "58px",
}) => (
  <StyledInputRadio withFlexWrap={withFlexWrap}>
    {values.map(({ value, icon }, index) => (
      <StyledInputWrapper
        isSelected={value === selectedValue}
        width={widthItem}
        key={index}
        height={heightItem}
      >
        <input
          type="radio"
          name={name}
          value={value}
          checked={value === selectedValue}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            onChange(event.target.value);
          }}
        />

        <div>{icon}</div>
      </StyledInputWrapper>
    ))}
  </StyledInputRadio>
);

export default InputRadio;
